import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Color from 'color';

const Label = ({ slug, title, color }) => (
  <Wrapper to={`/labels/${slug}`} color={color}>
    {title}
  </Wrapper>
);

const Wrapper = styled.li`
  border-radius: 16px;
  font-size: 16px;
  padding: 4px 16px;
  display: inline-block;
  margin-right: 8px;
  user-select: none;
  background-color: ${({ color }) => color.hex};
  color: ${({ color }) => (Color(color.hex).isDark() ? '#fff' : '#111')};
`;

export default Label;
