import React from 'react';
import Color from 'color';
import Slider from 'react-slick';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Label from '../components/Label';

export default ({ data }) => (
  <Layout>
    <article className="sheet">
      <HelmetDatoCms seo={data.datoCmsThing.seoMetaTags} />
      <div className="sheet__inner">
        <h1 className="sheet__title">{data.datoCmsThing.title}</h1>
        <p className="sheet__lead">{data.datoCmsThing.excerpt}</p>
        <div className="sheet__gallery">
          <Img fluid={data.datoCmsThing.coverImage.fluid} />
        </div>
        <div
          className="sheet__body"
          dangerouslySetInnerHTML={{
            __html: data.datoCmsThing.descriptionNode.children
              .map(({ html }) => html)
              .join(''),
          }}
        />

        <div className="sheet__slider">
          <Slider infinite={true} slidesToShow={2} arrows>
            {data.datoCmsThing.gallery.map(
              ({ fluid }) =>
                fluid && (
                  <img
                    alt={data.datoCmsThing.title}
                    key={fluid.src}
                    src={fluid.src}
                  />
                ),
            )}
          </Slider>
        </div>

        <div>
          <ul>
            {data.datoCmsThing.labels.map(({ title, slug, color }) => (
              <Label key={title} color={color} slug={slug} title={title} />
            ))}
          </ul>
        </div>
      </div>
    </article>
  </Layout>
);

export const query = graphql`
  query WorkQuery($slug: String!) {
    datoCmsThing(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      excerpt
      labels {
        title
        slug
        color {
          hex
        }
      }
      gallery {
        fluid(maxWidth: 200, imgixParams: { fm: "jpg", auto: "compress" }) {
          src
        }
      }
      descriptionNode {
        children {
          ... on MarkdownRemark {
            id
            html
          }
        }
      }
      coverImage {
        url
        fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
    }
  }
`;
